/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-23 19:23:57
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-30 12:23:57
 * @FilePath: /mediatom-web/src/apiForManage/flow/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const flowApi = {
  getPublisherDspLink: '/publisher/dspLink',
  getPnblisherDspList: '/publisher/dspList',
  updatePublisherDsp: '/publisher/batchUpdate',
  bindPublisherDsp: '/publisher/batchAdd',
  updatePublisherDspSts: '/publisher/batchUpdateSts',
  batchCopySource: '/publisher/batchCopy',
  batchUpdateQPS: '/trafficStrategy/batchUpdate',
  frequencyControl: '/publisher/frequencyControl'
}

/**
 * 获取绑定广告源列表
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function getPublisherDspLink (data) {
  return request({
    url: flowApi.getPublisherDspLink,
    method: 'post',
    data
  })
}

/**
 * 获取广告源列表tree
 * @export
 * @param {object} data
 * @return {Promise}
 */
export function getPnblisherDspTreeList (data) {
  return request({
    url: flowApi.getPnblisherDspList,
    method: 'post',
    data
  })
}

/**
 * 编辑利润（单个、批量）
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function updatePublisherDsp (data) {
  return request({
    url: flowApi.updatePublisherDsp,
    method: 'post',
    data
  })
}

/**
 * 绑定广告源（单个、批量）
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function bindPublisherDsp (data) {
  return request({
    url: flowApi.bindPublisherDsp,
    method: 'post',
    data
  })
}

/**
 * 修改广告源状态（单个、批量）
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function updatePublisherDspSts (data) {
  return request({
    url: flowApi.updatePublisherDspSts,
    method: 'post',
    data
  })
}

/**
 * 批量复制广告源
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function batchCopySource (data) {
  return request({
    url: flowApi.batchCopySource,
    method: 'post',
    data
  })
}

/**
 * 批量编辑QPS
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function batchUpdateQPS (data) {
  return request({
    url: flowApi.batchUpdateQPS,
    method: 'post',
    data
  })
}

/**
 * 流量配置频次控制
 * @export
 * @param {object} data
 * @return {Promise}
 * */
export function frequencyControl (data) {
  return request({
    url: flowApi.frequencyControl,
    method: 'post',
    data
  })
}
